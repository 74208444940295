import React, { useMemo, useState } from "react";
import { Form, Input, Switch, Button, message, Modal, Tabs, List , Popconfirm} from "antd";
import useUser_ from "../hook/useUser_";
import { useMCtx } from "../lib/MainContext";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useDateformat from "../hook/useDateformat";
import { PhoneOutlined } from "@ant-design/icons";


const UserNoteModal = () => {
  const queryClient = useQueryClient();
  const { state, dispatch } = useMCtx();
  const { addNoteReq, getUserNoteByAdminId, deleteNoteReq } = useUser_();
  const [note, setNote] = useState({
    adminName: "",
    note: "",
    isCall: false,
    adminId: null,
  });


  // fetch all notes by noteId
  const { data , isLoading:notesLoading } = useQuery(['admin-notes', state?.modalData?._id], () => {
    return getUserNoteByAdminId(state?.modalData?._id)
  },{
    enabled:state?.modalData?._id ? true : false,
  })

  

  // Build Add Note Reqeust
  const { mutate, isLoading } = useMutation(addNoteReq, {
    onSuccess: () => {
      message.success("Note Added Successfully");
      // dispatch({ type: "CLOSE_MODAL", payload: "NONE" });
      queryClient.invalidateQueries(["admin-notes", state?.modalData?._id]);

      // reset note
      setNote(p => {
        return {
          ...p,
          note:""
        }
      })
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  function submitNote() {
    if (note.note === "") {
      message.error("Note Must not be Empty ");
      return;
    }

    mutate(note);
  }

  useMemo(() => {
    if (state?.modalData) {
      setNote((p) => ({
        ...p,
        adminName: state?.modalData?.name,
        adminId: state?.modalData?._id,
      }));
    }
  }, [state]);


  // const [tabId, setTabId] = useState('1')
  function onChangeTab(key){
    // setTabId(key);
  }

  const { basicFormat } = useDateformat();
  const [deletingId, setDeletingId] = useState(null)
  const { mutate: noteDeleteAction, isLoading: noteDeleteLoading } =
  useMutation(deleteNoteReq, {
    onSuccess: (res, id) => {
      message.success("Note Deleted");
      queryClient.invalidateQueries(["admin-notes", state?.modalData?._id]);
    },
    onError: (err) => {
      message.error(err?.response?.data?.message);
    },
  });

  return (
    <>

    <Modal title={`Note of ${state?.modalData?.name}`} visible={true} centered onCancel={e => {
      dispatch({ type: "CLOSE_MODAL", payload: "NONE" });
    }} footer={[]}>


        <Tabs defaultActiveKey="1" onChange={onChangeTab} >
          <Tabs.TabPane tab='Note List' key={'1'}>
            
            <div>
              {/* // Notes List  */}

              <List
                  loading={notesLoading}
                  itemLayout='horizontal'
                  dataSource={data}
                  renderItem={(note) => (
                    <List.Item
                      actions={[
                        <a href='#' key='list-loadmore-edit'>
                          {basicFormat(note?.createdAt)}
                        </a>,
                        <Popconfirm
                          key='list-loadmore-more'
                          title='Are you sure to Delete ?'
                          onConfirm={(_) => {
                            noteDeleteAction(note?._id);
                            setDeletingId(note?._id)
                          }}
                        >
                          <Button loading={noteDeleteLoading && deletingId===note?._id} size='small'>
                            Delete
                          </Button>
                        </Popconfirm>,
                      ]}
                    >
                      {note?.isCall && <PhoneOutlined />} {note?.note}
                    </List.Item>
                  )}
                />
              

            </div>
            
          </Tabs.TabPane>

          <Tabs.TabPane tab="New Note" key={'2'}>
            <Form layout='vertical'>
                <Form.Item label='Call'>
                  <Switch onChange={(e) => setNote((p) => ({ ...p, isCall: e }))} />
                </Form.Item>
                <Form.Item label='Type note about Selected User'>
                  <Input.TextArea
                    value={note?.note}
                    onChange={(e) => setNote((p) => ({ ...p, note: e?.target?.value }))}
                    placeholder='Type note'
                  />
                </Form.Item>
                <Form.Item>
                  <Button loading={isLoading} onClick={submitNote}>
                    Submit
                  </Button>
                </Form.Item>
              </Form>
          </Tabs.TabPane>
        </Tabs>



      
    </Modal>
    </>
  );
};

export default UserNoteModal;
