import { useAxios } from "./../data/AxiosProvider";
import { useQuery } from "react-query";


const requestLatency = async (payload) => {

};


export function useRequestLatency(payload){
  const axios = useAxios();
  const { page,pageSize } = payload;
  return useQuery(['request latency', page, pageSize], async() => {
    const { data } = await axios.get("/administrator/request-latency", {
      params:payload
    });
    return data;
  }, {
    refetchInterval:1000
  })
}