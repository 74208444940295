import { useState } from 'react';
import { useAxios } from './AxiosProvider';
import { useQuery, useMutation } from 'react-query';
import { message } from 'antd';
import { useMCtx } from "../lib/MainContext";
const usePayments = () => {
  const axios = useAxios();
  const { dispatch } = useMCtx();
  const [status, setStatus] = useState(false);

  const softwareUpdateSignal = async () => {
    const { data } = await axios.get("/administrator/software-update-signal");
    return data;
  };


  const fetchUsersMembers = async () => {
    const { data } = await axios.get("/administrator/members");
    return data;
  };

  const liveuserFetchRequest = async () => {
    const { data } = await axios.get("/administrator/live-visitor");
    return data;
  };

  const fetchOpinions = async () => {
    const { data } = await axios.get("/administrator/opinions");
    return data;
  };

  const userAuthentication = async (info) => {
    const { data } = await axios.post("/auth/administration-admin-login", info);
    return data;
  };

  const getLastFiftyLog = async () => {
    const { data } = await axios.get("/administrator/last-fifty-logs");
    return data;
  };

  ////////////////// Total User Count
  const totalUserCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-user-count");
    return data;
  };

  // Total SMS Balance
  const totalUserSMSBalanceRequest = async () => {
    const { data } = await axios.get("/administrator/total-user-sms-balance");
    return data;
  };
  // SMS Send Count
  const SmsSendCountRequest = async () => {
    const { data } = await axios.get("/administrator/sms-send-count-state", {
      params: {
        date: new Date().getTime(),
      },
    });
    return data;
  };

  const { data: totalUserCount } = useQuery(
    "total-user-count",
    totalUserCountRequest,
    {
      refetchOnWindowFocus: false,
		onSuccess: (userCount) => {
		  dispatch({ type: "TOTAL_USER_COUNT", payload: userCount });
      },
    }
  );

  const { data: totalUserSMSBalance } = useQuery(
    "user-total-sms-balance",
    totalUserSMSBalanceRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: smsSendState } = useQuery(
    "sms-send-count",
    SmsSendCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

 const {data: memberAddCountByDate } =  useQuery(['member-add-count-by-date'], async() => {
    const {data} = await axios.get('/administrator/member-count-by-date', {
      params:{
        _date:new Date().getTime()
      }
    });
    return data;
  }, {
    refetchOnWindowFocus:false
  })

  //////////////////////////////////////////////

  ////////////////// Total User Count
  const totalMemberCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-member-count");
    return data;
  };
  const { data: totalMemberCount } = useQuery(
    "total-member-count",
    totalMemberCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );
  //////////////////////////////////////////////

  ////////////////// Total User Count
  const totalActiveMemberCountRequest = async () => {
    const { data } = await axios.get(
      "/administrator/total-active-member-count"
    );
    return data;
  };
  const { data: totalActiveMemberCount } = useQuery(
    "total-active-member-count",
    totalActiveMemberCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );
  //////////////////////////////////////////////
  ////////////////// Total User Count
  const totalLoanCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-loan-count");
    return data;
  };
  const { data: totalLoanCount } = useQuery(
    "total-loan-count",
    totalLoanCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  //////////////////////////////////////////////

  ////////////////// Total User Count
  const totalActiveLoanCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-active-loan-count");
    return data;
  };
  const { data: totalActiveLoanCount } = useQuery(
    "total-active-loan-count",
    totalActiveLoanCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  //////////////////////////////////////////////

  ////////////////// Total Savings Count
  const totalSavingsCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-savings-count");
    return data;
  };
  const { data: totalSavingsCount } = useQuery(
    "total-savings-count",
    totalSavingsCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  //////////////////////////////////////////////

  ////////////////// Total active Savings Count
  const totalActiveSavingsCountRequest = async () => {
    const { data } = await axios.get(
      "/administrator/total-active-savings-count"
    );
    return data;
  };
  const { data: totalActiveSavingsCount } = useQuery(
    "total-active-savings-count",
    totalActiveSavingsCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  //////////////////////////////////////////////

  ////////////////// Total FDR Count
  const totalFdrCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-fdr-count");
    return data;
  };
  const { data: totalFdrCount } = useQuery(
    "total-fdr-count",
    totalFdrCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  //////////////////////////////////////////////

  ////////////////// Total active FDR Count
  const totalActiveFdrCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-active-fdr-count");
    return data;
  };
  const { data: totalActiveFdrCount } = useQuery(
    "total-active-fdr-count",
    totalActiveFdrCountRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  //////////////////////////////////////////////

  // query
  const { data: lastFiftyLogs } = useQuery("last-50-logs", getLastFiftyLog, {
    refetchOnWindowFocus: false,
  });

  const { data: members, isLoading: memberLoading } = useQuery(
    "members",
    fetchUsersMembers,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: allVisitors, isLoading: visitorLoading } = useQuery(
    "live-visitor",
    liveuserFetchRequest,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: opinions, isLoading: opinionFetching } = useQuery(
    "opinions",
    fetchOpinions,
    {
      refetchOnWindowFocus: false,
    }
  );

  const sendMessageToMember = async (message) => {
    const { data } = await axios.post("/administrator/member-message", message);
    return data;
  };

  const { mutate: sendMessageAction, isLoading: messageSending } = useMutation(
    sendMessageToMember,
    {
      onSuccess: () => {
        message.success("Message Sent");
        setStatus(true);
      },
      onError: (err) => {
        message.error(err.response.data.message);
      },
    }
  );

  const { mutate: userAuthAction } = useMutation(userAuthentication, {
    onSuccess: (res) => {
      message.success("auth success ");
      // //
      let url;
      if (String(window.location.href).indexOf("localhost") > -1) {
        url = "http://localhost:3000/";
      } else {
        url = "https://somity.binarybunon.com/";
      }

      window.open(`${url}inj-token/${res?.token}`, "_blank");
    },
    onError: (err) => {
      message.error(err.response.data.message);
    },
  });

  const { mutate: updateSignalAction, isLoading: updateSignalLoading } =
    useMutation(softwareUpdateSignal, {
      onSuccess: (res) => {
        message.success("Signal send to All User ");
      },
      onError: (err) => {
        message.error(err.response.data.message);
      },
    });

  return {
    members,
    memberLoading,
    sendMessageAction,
    messageSending,
    status,
    setStatus,
    allVisitors,
    visitorLoading,
    userAuthAction,
    opinions,
    opinionFetching,
    updateSignalAction,
    updateSignalLoading,
    totalUserCount,
    totalMemberCount,
    totalActiveMemberCount,
    totalLoanCount,
    totalActiveLoanCount,
    totalSavingsCount,
    totalActiveSavingsCount,
    totalFdrCount,
    totalActiveFdrCount,
    lastFiftyLogs,
    totalUserSMSBalance,
    smsSendState,
    memberAddCountByDate
  };
};

export default usePayments;
