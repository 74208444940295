import React from "react";
import { createRoot } from "react-dom/client";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AxiosProvider } from "./data/AxiosProvider";
import { BrowserRouter as Router } from "react-router-dom";
import { MainContextProvider } from "./lib/MainContext";
const queryClient = new QueryClient();

const RootApp = () =>  (
  <MainContextProvider>
    <Router>
      <AxiosProvider>
        <QueryClientProvider client={queryClient}>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </AxiosProvider>
    </Router>
  </MainContextProvider>
)


const container = document.getElementById("root")

const root = createRoot(container);
root.render(<RootApp/>);