import React, { useState, useEffect } from "react";
import { Table, Card, Tag, Button } from "antd";
import moment from "moment";
import { io } from "socket.io-client";
import { SyncOutlined } from "@ant-design/icons";
import useUser from "./../../data/useUser";
import { SOCKETURL } from "./../../context/config";
import { useQueryClient } from "react-query";
const Statictic = () => {
	const { allVisitors, visitorLoading } = useUser();
	const queryClient = useQueryClient();
	const [visitors, setVisitors] = useState([]);
	const [visitorCount] = useState(0);

	useEffect(() => {
		if (allVisitors !== undefined) {
			setVisitors(
				allVisitors.sort(
					(a, b) => new Date(b.lastActive) - new Date(a.lastActive)
				)
			);
		}
	}, [allVisitors]);

	useEffect(() => {
		const socket = io(SOCKETURL);

		socket.on("connect", () => {
			// //
			socket.emit("admin-connect", {});

			socket.on("users", (users) => {
				setVisitors(users);
			});
			socket.on("user-connect", (connectedUser) => {
				// //
				let temp = [];
				for (let i = 0; i < allVisitors.length; i++) {
					if (allVisitors[i].userId === connectedUser.userId) {
						// //
						temp.push(connectedUser);
					} else {
						temp.push(allVisitors[i]);
					}
				}
				setVisitors(temp);
			});

			socket.on("user-disconnect", (disConnectedUser) => {
				// //
				let temp = [];
				for (let i = 0; i < allVisitors.length; i++) {
					if (allVisitors[i].userId === disConnectedUser.userId) {
						// //
						temp.push(disConnectedUser);
					} else {
						temp.push(allVisitors[i]);
					}
				}
				setVisitors(temp);
			});
		});

		return () => socket.disconnect();
		// eslint-disable-next-line
	}, []);

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "Phone",
			dataIndex: "phone",
			key: "phone",
		},
		{
			title: "Last Active",
			dataIndex: "lastActive",
			key: "lastActive",
			render: (date) => (
				<>
					{moment(date).format("ll")} &nbsp;
					{moment(date).format("LTS")}
				</>
			),
		},
		{
			title: "Status",
			dataIndex: "disconnect",
			key: "disconnect",
			render: (status) => (
				<>
					{!status ? (
						<Tag style={{ color: "#fff" }} color="#19a15f">
							online
						</Tag>
					) : (
						<Tag style={{ color: "#fff" }} color="#dd5145">
							Offline
						</Tag>
					)}
				</>
			),
		},
	];

	return (
		<div>
			<Card
				title="Live Visitor Data"
				extra={[
					<Tag key="count">Live Visitor Count : {visitorCount}</Tag>,
					<Button
						onClick={() => queryClient.invalidateQueries("live-visitor")}
						icon={visitorLoading ? <SyncOutlined spin /> : <SyncOutlined />}
					></Button>,
				]}
			>
				<div className="responsive">
					<Table
						size="small"
						pagination={false}
						dataSource={visitors}
						columns={columns}
						rowKey="_id"
					/>
				</div>
			</Card>
		</div>
	);
};

export default Statictic;
