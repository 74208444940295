import React from "react";
import { useMCtx } from "../lib/MainContext";
import UserNoteModal from "./UserNoteModal";
import { Button, Modal } from "antd";
import UserDetails from "./UserDetails";
const Modals = () => {
  const { state } = useMCtx();
  const { isModalOpen, ActiveModal } = state;
  
  if (!isModalOpen) return <></>;
  if (ActiveModal === "USER_NOTE") return <UserNoteModal />;
  if (ActiveModal === "USER_DETAILS") return <UserDetails />;

  return <></>;
};

export default Modals;
