import React, { useState } from "react";
import { message } from "antd";
import useAuth from "./../../../data/useAuth";

import "./Login.css";
const Login = ({ isMobile }) => {
	const { authenticate, authenticating } = useAuth();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const LoginHandler = () => {
		if (username === "") {
			message.error("ফোন নাম্বার লিখুন");
		} else if (password === "") {
			message.error("পাসওয়ার্ড দিন");
		} else {
			authenticate({ username, password });
		}
	};

	return (
		<>
			<div className="login__wrapper">
				<div className="left-bg-color" />
				<h1 className="logo__login">বিবি সমিতি</h1>
				<div className="dialogue">
					<div>ভাবনাহীন সমিতি পরিচালনায় </div>
					<div>অপার সম্ভাবনার দ্বার উন্মোচন করুন</div>
					<div style={{ paddingTop: 20 }}>Administration Panel </div>
				</div>
				<br />

				<div className="login__form-container">
					<form>
						<div>
							<input
								className="login-input-field"
								value={username}
								onChange={(e) => setUsername(e.target.value)}
								id="emailOrusername"
								placeholder="username"
							/>
						</div>
						<div>
							<input
								className="login-input-field"
								value={password}
								onKeyPress={(e) => {
									if (e.key === "Enter") {
										LoginHandler();
									}
								}}
								onChange={(e) => setPassword(e.target.value)}
								id="password"
								type="password"
								placeholder="পাসওয়ার্ড"
							/>
						</div>
						<button
							disabled={authenticating}
							onClick={LoginHandler}
							type="button"
							className="btn-log-in"
						>
							{authenticating ? "প্রবেশের চেস্টা চলছে ..." : " প্রবেশ করুন"}
						</button>
					</form>
				</div>
				<div className="footer">
					<a
						className="footer-link"
						target="_blank"
						rel="noreferrer"
						href="https://binarybunon.com/"
					>
						বাইনারি বুনন
					</a>
				</div>
			</div>
		</>
	);
};

export default Login;
