import { createContext, useContext, useReducer } from "react";

const defaultState = {
  userTypes: [],
  totalUserCount: 0,

  // Modal
  isModalOpen: false,
  // USER_NOTE
  ActiveModal: "USER_NOTE",
  modalData: null,
};

const mainContext = createContext(null);

// reducer
function mainReducer(state, action) {
  switch (action?.type) {
    case "STORE_USER_TYPES":
      return {
        ...state,
        userTypes: action.payload,
      };
    case "TOTAL_USER_COUNT":
      return {
        ...state,
        totalUserCount: action.payload,
      };

    case "OPEN_MODAL":
      return {
        ...state,
        isModalOpen: true,
        ActiveModal: action?.payload,
      };

    case "CLOSE_MODAL":
      return {
        ...state,
        isModalOpen: false,
        ActiveModal: action?.payload,
      };
    case "MODAL_DATA":
      return {
        ...state,
        modalData: action?.payload,
      };
    default:
      return state;
  }
}

// Provider
export function MainContextProvider({ children }) {
  const [state, dispatch] = useReducer(mainReducer, defaultState);

  return (
    <mainContext.Provider value={{ state, dispatch }}>
      {children}
    </mainContext.Provider>
  );
}

export function useMCtx() {
  const context = useContext(mainContext);
  if (!context) throw new Error("Out of Main Context");
  return context;
}
