// import { useState } from "react";
import { useAxios } from "./../data/AxiosProvider";
// import { message } from "antd";
const useUser_ = () => {
  const axios = useAxios();

  // Fetch All Types
  const fetchUserTypes = async () => {
    const { data } = await axios.get("/administrator/user-type/read");
    return data;
  };

  // Fetch User by Type
  const fetchUsersByTypeId = async (payload) => {
    const { typeId } = payload;
    const { data } = await axios.get(
      `/administrator/users/by-typeid/${typeId}`,
      { params: payload }
    );
    return data;
  };

  // Change User Type

  const changeUserType = async (payload) => {
    const { data } = await axios.patch(
      `/administrator/users/change-user-type`,
      payload
    );
    return data;
  };

  // Add User Type
  const addUserType = async (payload) => {
    const { data } = await axios.post("/administrator/user-type/add", payload);
    return data;
  };

  // Update user Type
  const updateUserType = async (payload) => {
    const { _id } = payload;
    const { data } = await axios.patch(
      `/administrator/user-type/update/${_id}`,
      payload
    );
    return data;
  };

  // Delete User Type
  const deleteUserType = async (payload) => {
    const { _id } = payload;
    const { data } = await axios.delete(
      `/administrator/user-type/delete/${_id}`
    );
    return data;
  };

  const userAuthentication = async (info) => {
    const { data } = await axios.post("/auth/administration-admin-login", info);
    return data;
  };

  const fetchUsersRequests = async (payload) => {
    const { data } = await axios.get("/administrator/users", {
      params: {
        ...payload,
      },
    });
    return data;
  };

  const sendMessageToMember = async (message) => {
    const { data } = await axios.post("/administrator/member-message", message);
    return data;
  };

  // Note

  const getUserNoteByAdminId = async (id) => {
    const { data } = await axios.get("/administrator/get-user-notes/" + id);
    return data;
  };


  const addNoteReq = async (payload) => {
    const { data } = await axios.post("/administrator/add-user-note", payload);
    return data;
  };

  const deleteNoteReq = async (id) => {
    const { data } = await axios.get("/administrator/delete-user-note/" + id);
    return data;
  };

  

  const totalUserCountRequest = async () => {
    const { data } = await axios.get("/administrator/total-user-count");
    return data;
  };



  // get user details by adminid
  const getAdminUserDetailsByAdminId = async (id) => {
    const { data } = await axios.get("/administrator/user-details/" + id);
    return data;
  };



  return {
    userAuthentication,
    fetchUsersRequests,
    sendMessageToMember,
    addNoteReq,
    deleteNoteReq,
    fetchUserTypes,
    fetchUsersByTypeId,
    addUserType,
    updateUserType,
    deleteUserType,
    changeUserType,
    totalUserCountRequest,
    getUserNoteByAdminId,
    getAdminUserDetailsByAdminId
  };
};

export default useUser_;



