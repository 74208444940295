import React from 'react'
import useUser_ from "../hook/useUser_";
import { useMCtx } from "../lib/MainContext";
import { Card, Modal } from 'antd';
import { useQuery, useQueryClient } from 'react-query';

const UserDetails = () => {

  const queryClient = useQueryClient();
  const { state, dispatch } = useMCtx();

  const { getAdminUserDetailsByAdminId } = useUser_();

  const { data , isLoading:detailsLoading } = useQuery(['admin-details', state?.modalData?._id], () => {
    return getAdminUserDetailsByAdminId(state?.modalData?._id)
  },{
    enabled:state?.modalData?._id ? true : false,
  })

  


  return (
    <>
    <Modal title={`Details of ${state?.modalData?.name}`} visible={true} centered onCancel={e => {
      dispatch({ type: "CLOSE_MODAL", payload: "NONE" });
    }} footer={[]}>
      <div className='user-details-card-wrapper'>
        <div>
          <span>{data?.member?.total}</span>
          <span>Total Member</span>
        </div>
        <div>
          <span>{data?.member?.total - (data?.member?.disabled + data?.member?.deleted)}</span>
          <span>Active Member</span>
        </div>
        <div>
          <span>{data?.member?.disabled}</span>
          <span>In Active Member</span>
        </div>
        <div>
          <span>{data?.transections?.balance}</span>
          <span>Current Balance (Main)</span>
        </div>
        <div>
          <span>{data?.transections?.cashIn}</span>
          <span>Total Recharge (Main)</span>
          {/*  */}
        </div>
        <div>
          <span>{isNaN(parseFloat(data?.smsTransections?.cashIn - data?.smsTransections?.charge).toFixed(2))? 0:parseFloat(data?.smsTransections?.cashIn - data?.smsTransections?.charge).toFixed(2)}</span>
          <span>Current Balance (SMS)</span>
        </div>
        <div>
          <span>{isNaN(parseFloat(data?.smsTransections?.cashIn).toFixed(2)) ?0:parseFloat(data?.smsTransections?.cashIn).toFixed(2) }</span>
          <span>Total Recharge (SMS)</span>
        </div>
        <div>
          <span>All Okey</span>
          <span>Current Status</span>
        </div>
      </div>
    </Modal>
    </>
    
  )
}

export default UserDetails