import { Button, Card, Table, Pagination } from 'antd'
import React, { useState } from 'react'
import { useRequestLatency } from '../../hook/request';
import moment from 'moment';
import { basicFormat } from "@binarybunon/common"
import { SyncOutlined } from "@ant-design/icons"

const RequestLatency = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);

  const [largeReq, setLargeReq] = useState('false')
  const { data } = useRequestLatency({ page, pageSize , largeReq})
  

  const columns = [
    {
      title: 'Time',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render:createdAt => <>{ basicFormat(createdAt) } {moment(createdAt).format('LTS')}</>
    },
    {
      title: 'Path',
      dataIndex: 'path',
      key: 'path',
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: 'time',
      render:time => <>{parseFloat(time).toFixed(2)}ms</>
    },
  ];



  return (
    <div>
      <Card extra={[
        
        

        <span style={{ display:'flex', justifyContent:'space-between', alignItems:'center' }}>
          <Button key={'sort-btn'} onClick={e => setLargeReq(p => {
          return p===true?false:true;
          })} type={largeReq===true?'primary':'dashed'}>Sort By Large Reqeust</Button>
          <Button key={'live'}>
            <SyncOutlined spin />
          </Button>
          <Pagination onChange={(e,d) => {
            setPage(e);
            setPageSize(d)
          }} total={data?.count || 0} size='small' key={'pagination'} />,
        </span>
        

      ]} title="Realtime Request Latency">
        <Table pagination={false} bordered={true} rowKey={'_id'} size='small' columns={columns} dataSource={data?.requests ? data?.requests:[]} />
      </Card>
    </div>
  )
}

export default RequestLatency